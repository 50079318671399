@import '../../../../theme/theme.scss';

.navgationBarMobile {
	background: $bg-primary;
	color: $primary-text;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 8px;

	.hamburgerIcon {
		cursor: pointer;
	}

	.logo {
		margin-left: 25px;
		vertical-align: middle;
	}

	.navWrapperLeft, .navwrapperRight {
		position:relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

@media only screen and (max-width: $b-small ) {  
	.navgationBarMobile {
		.logo {
			margin-left: 10px;
		} 
	}
}

