@import '../../../../theme/theme.scss' ;

.search {
  position: relative;
  margin-right: 15px;

  input {
    border: none;
    height: 30px;
    padding: 0 10px;
    border-radius: 13px;
  }

  .searchIcon {
    position: absolute;
    right: 7px;
    top: 3px;
  }

}



@media only screen and (max-width: $b-xlarge ) {
  .search {
  
    input {
      height: 23px;
      width: 180px;
    }
  
    .searchIcon {
      position: absolute;
      right: 7px;
      top: -1px;
    }
  
  }
}

@media only screen and (max-width: $b-medium ) {
  .search {
    margin-right: 0;
  }
}

