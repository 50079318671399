@import '../../../../theme/theme.scss';

.triangle {
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  
  border-bottom: 12px solid rgba(42, 42, 42,0.90);

  position: absolute;
  top: -12px;
}

.hide {
  display: none;
}


.hamburgerWrapper {
  cursor: pointer;
  svg {
    display: block;
  }
}

.navlistMobile {
  position: absolute;
  color: $primary-text;
  background: rgba(42, 42, 42,0.90);
  padding: 0px 10px;
  top: 42px;
  width: 170px;
  z-index: 50;
  left: -15px;
  font-size: 15px;
  font-weight: 200;;
  ul {
      padding-left: 0;
      
  }

  li {
   padding: 5px;
   display: block;
  }

}