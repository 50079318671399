@import '../../../../theme/theme.scss';
.hide {
  display: none;
}

.navlist {

  ul {
    padding-left: 0;
  }

  li {
    padding: 5px;
    margin-left: 5vw;
    text-transform: uppercase;
    font-weight: 500;
  }
 
}

@media only screen and (max-width: $b-xlarge ) {
  .navlist {
    li {
      margin-left: 2vw;
      font-size: 14px
    }
   
  }
}

@media only screen and (max-width: $b-large ) {
  .navlist {

    li {
      margin-left: 0.5vw;
      font-size: 13px
    }
   
  }
}