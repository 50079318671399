@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura-light.woff2") format("woff2");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura-regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura-medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura-bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Futura";
  src: url("../assets/fonts/Futura-heavy.woff2") format("woff2");
  font-style: normal;
  font-weight: 800;
}