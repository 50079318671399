@import '../../../theme/theme.scss';

.SectionSliderItem {
  color: $primary-text;
  display: flex;
  align-items: center;
  padding-bottom: 15px;


  color: #FFFFFF;
  display: flex;
  align-items: center;
  /* padding-bottom: 15px; */


  .cover {
    img{ 
      width: 100%;
      height: auto;
    }
  }

  .description {
    width: 400px;
    padding: 20px 20px 20px 40px;
    font-weight: 300;

    h3 {
      font-weight: bold;
    }

  }
}

.bgCoverLoading {
  width: 9vw;
  height: 13vw;
  background: black;
}

@media only screen and (max-width: $b-medium ) {
  .SliderItem {
    flex-direction: column;

    .description {
      text-align: center;
    }
  }
}
