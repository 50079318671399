@import "../../theme/theme.scss";

.mediaSliderWrapper {
  width: 100%;
  height: 30vw;
  background: black;
  animation: loaderCover 2s infinite;



 

  //sobrepoe o css do swipper posicionando as bolinhas da navegação um pouco mais pra cima pois nesse banner fica melhor assim
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 10px !important;
    }
    .swiper-pagination-bullet-active {
      background: $secundary-color;
    }
  }

  @-webkit-keyframes loaderCover {
    0% { background-color: rgba(0, 0, 0, 0.22) }
    50% {background-color: rgba(0, 0, 0, 0.22) }
     0% { background-color: rgba(0, 0, 0, 0.22) }
 }
 
 @keyframes loaderCover {
    0% { background-color: rgba(0, 0, 0, 0.22) }
    50% {background-color: rgba(0, 0, 0, 0.22) }
    0% { background-color: rgba(0, 0, 0, 0.22) }
 }
