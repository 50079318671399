@import '../../theme/theme.scss';

.sectionSliderWrapper {
    width: 95%;
    margin: 40px auto 0 auto;

    .titleSection {
      font-weight: 500;
      color: white;
      font-size: 20px;
      text-transform: uppercase;
    }

  //customnizações visuais do swiper 
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 0px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 0px;
  }
  .swiper-button-prev, .swiper-button-next {
    top: 7px;
    color: #ffffff !important;
    background: #00000066;
    height: 100%;
    padding: 0 10px;
    width: 40px;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -4px !important;
  }
  .swiper-pagination-bullet-active {
    background: $secundary-color;
  }

  .swiper-button-prev, .swiper-button-next {
    color: $secundary-color!important;
  }

}

@media(max-width: $b-medium) {
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .sectionSliderWrapper {
    margin: 20px auto 0 auto;
  }

  .sectionSliderWrapper .titleSection {
    font-size: 12px;
  }
}
