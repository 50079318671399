@import 'theme.scss';


* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;

  font-family: $f-family;
  background-color: $bg-primary;

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: $primary-text;
    text-transform: uppercase;
  }

  img, svg {
    vertical-align: middle;
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

  }

  -webkit-font-smoothing: antialiased;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup {
    margin: 0;
    padding: 0;
  }

  input[type='text'],
  textarea {
    font-size: 16px;
  }
}

  


  