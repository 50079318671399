@import '../../theme/theme.scss';

.MovieDetailContent {
  padding: 50px 0 20px 0;
  color: $primary-text;

  .topDetail {
    display: flex;
    justify-content: center;

    .coverDetail {
      padding: 0 20px 0 0 ;

      img {
        max-width: 200px;
      }
    }

    .descriptionDetail {
      width: 600px;
      font-size: 14px;
    }
  }

  .bottomDetail {
      text-align: center;
      display: flex;
      justify-content: center;
      font-weight: bold;

    .trailerWrapper {
      text-align: center;
      width: 500px;
      margin-top: 50px;
     
      h3 {
        padding-bottom: 20px;
      }

      video {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $b-medium ) {
  .MovieDetailContent {
    .topDetail {
      flex-direction: column;
      align-items: center;

      .descriptionDetail {
        width: auto;
        margin-top: 20px;

        .movieDetailTitle {
          font-size: 22px;
        }
      }
    } 
  }
}






