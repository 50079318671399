@import '../../theme/theme.scss';

.moviesContent {

  .moviesTitle {
    margin: 50px 0 30px 0;
  }

  .loading {
    text-align: center;
    margin: 40px 0;
  }
}
