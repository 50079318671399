@import '../../theme/theme.scss';

.contactTitle {
  font-weight: 300;
  margin: 50px 0 40px 0;
}

.profileWGrouprapper {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 30px;
}

@media(max-width: $b-large) {
  .contactTitle {
    font-size: 26px;
    text-align: center;
  }
  .profileWGrouprapper {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  
    .profileWrapper {
      margin-left: 0;
    }

  
  }
}

@media(max-width: $b-small) {
  .profileWGrouprapper { 
    grid-template-columns: 1fr;
    .profileWrapper {
      margin-top: 20px;
    }
  
  }
}



