@import '../../theme/theme.scss';

.movieTabList {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px #4a4a4a solid;
}

@media only screen and (max-width: $b-xlarge ) {
  .movieTabList {
    justify-content: center;
    font-size: 13px;
  } 
}

