@import '../../theme/theme.scss';

.bannerAbout {
  width: 100%;
}


.titleAbout {
  text-transform: initial;
  margin: 30px 0;
}