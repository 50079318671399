@import './theme/global.scss';

.container, .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


@media only screen and (min-width: $b-medium ) {
    .container {
        width: 750px;
    }
}


@media only screen and (min-width: $b-large ) {
    .container {
        width: 992px;
    }
}

@media only screen and (min-width: $b-xlarge ) {
    .container {
        width: 1170px;
    }
}




