@import './fonts.scss';

//colors
$bg-primary: #202020;
$bg-secundary: #000000;
$bg-terceary: rgba(0, 0, 0, 0.13);
$bg-quarterly: #FFFFFF;
$primary-color: #000000;
$secundary-color: #FFFFFF;
$primary-text: #FFFFFF;
$secundary-text: #000000;
$terceary-text: #b5b382;


//spacing
$s-xxsmall: 0.8rem;
$s-small: 2.4rem;
$s-xsmall: 1.6rem;
$s-medium: 3.2rem;
$s-large: 4.0rem;
$s-xlarge: 4.8rem;
$s-xxlarge: 5.6rem;

//fonts
$f-family: 'Futura', Helvetica, Arial, sans-serif;
$f-secundary: Helvetica, Arial, sans-serif;
$f-size-xxsmall:0.8rem;
$f-size-xsmall:1.1rem;
$f-size-small: 1.4rem;
$f-size-medium: 1.6rem;
$f-size-large: 1.8rem;
$f-size-xlarge: 2.0rem;
$f-size-xxlarge: 2.8rem;

//breakpoints
$b-small: 576px;
$b-medium: 768px;
$b-large: 992px;
$b-xlarge: 1300px;


//layer
$tooltip-layer: 1;
$menu-layer: 2;
$popup-layer: 3;