@import '../../../theme/theme.scss';

.bannerSliderItem {

  .fluid-image {
    width: 100%;
    height: auto;
  }

}

