@import '../../../theme/theme.scss';

.header {  
  margin: 0 -15px;
  position: sticky; 
  top: 0;

  z-index: $menu-layer;
  .topHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

  }
}

@media only screen and (max-width: $b-large ) {
  .logo {
    width: 70px;
  }
}

@media only screen and (max-width: $b-small ) {
  .logo {
    width: 50px;
  }
}