@import '../../../theme/theme.scss';

.FallbackCover {
  position: relative;
  p {
    position: absolute;
    top: 0;
    padding: 15px 10px;
    margin: 0;
    color: white;
    font-size: 13px;
  }
}


@media only screen and (max-width: $b-medium ) {
  .FallbackCover {
    p {
      padding: 5px 3px;
      font-size: 11px;
      text-align: center;
      line-height: 1;
    }
  }
}

@media only screen and (max-width: $b-small ) {
  .FallbackCover {
    p {
      font-size: 10px;
    }
  }
}
