@import '../../../theme/theme.scss';

.footer {
  width: 100%;
  text-align: center;
  padding: 40px 20px;
  color: $primary-text;

  .showInMedumSize {
    display: none;
  }

  ul {
    padding: 0;
  }
}

@media(max-width: $b-large) {
  .footer {
    .showInMedumSize {
      display: block;
    }
  }
}


@media(max-width: $b-small) {
  .footer {
    font-size: 11px;
  }
}

