@import '../../../theme/theme.scss';

.movieGridItem {
  padding: 5px;
  position: relative;
  border-radius: 4px;
  margin: 5px;

  .cover {
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.35);
    max-width: 200px;
  }
}

@media only screen and (max-width: $b-medium ) {
  .movieGridItem {
    .cover {
      width: 150px;
    }
  } 
}

@media only screen and (max-width: $b-small ) {
  .movieGridItem {
    .cover {
      width:100px;
    }
  } 
}