@import '../../../theme/theme.scss';

.SliderItem {
  color: $primary-text;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;

  img {
    max-width: 200px;
  }

  .description {
    width: 400px;
    padding: 20px 20px 20px 40px;
    font-weight: 300;

    h3 {
      font-weight: bold;
    }

  }
}

@media only screen and (max-width: $b-medium ) {
  .SliderItem {
    flex-direction: column;

    .description {
      text-align: center;
    }
  }
}
