@import '../../theme/theme.scss';

.movieGrid {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
}


@media only screen and (max-width: $b-medium) {
  .movieGrid {
    justify-content: space-evenly;
  }
}
