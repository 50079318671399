@import '../../../../theme/theme.scss';


.selectLangGroup {

  position: relative;
  margin: 0 20px;
  margin: 0 7px 0 10px;
  .selectecFlag {
    img {
      display: block;
    }
  }

  .flagLang {
    cursor: pointer;
  
    img {
      border-radius: 4px;
      width: 25px;

    }
  }

  .triangleLang {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $bg-quarterly;
    position: absolute;
    top: 34px;
    z-index: $menu-layer;
  }
  
  .selectLangWrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    background-color: $bg-quarterly;
    border: 1px solid #f8f8f8;
    top: 45px;
    right: -13px;
    width: 70px;
    border-radius: 5px;
    box-shadow: 0px 1px 10px black;
    color: #000000;
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 200;
    margin: 0;

    li {
      list-style: none;
      text-align: left;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: #f2f2f2;
      }

      span {
        width: 90px;
        padding: 5px 6px;
        display: block;
      }
    }

  }

}

@media only screen and (max-width: $b-small ) {  
  .selectLangGroup {
    margin: 0 10px;
  }
}

