@import '../../theme/theme.scss';

.newsContent {
  .newsTitle {
    margin: 70px 0 50px 0;
  }
  a {
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 300;
  }
}


@media(max-width: $b-small) {
  .newsTitle {
    font-size: 24px;
  }
}