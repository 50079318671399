@import '../../theme/theme.scss';

.moviesContent {
  .selectOrderWrapper {

    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background: #2c2c2c;
      background-image: none;
      flex: 1;
      padding: 0 .5em;
      color: #fff;
      cursor: pointer;
      font-size: 16px;
  
      &::-ms-expand {
        display: none;
      }
    }
  
    .selectOrder {
      position: relative;
      display: flex;
      width: 200px;
      height: 34px;
      line-height: 3;
      background: $bg-primary;
      overflow: hidden;
      border-radius: .25em;
  
      &:after {
        content: '\25BC';
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 1em;
        background: #1a1a1a;
        cursor: pointer;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
        font-size: 12px;
      }
  
      &:hover:after {
        color: $bg-quarterly;
      }
    }

  }
  
  .moviesTitle {
    margin: 50px 0;
    text-transform: initial;
    font-weight: 500;
  }

  .loading {
    text-align: center;
    margin: 40px 0;
  }
 
}

.centered {
  text-align: center;
}