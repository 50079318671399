@import '../../../../theme/theme.scss';

.navgationBar {
    background: $bg-primary;
    color: $primary-text;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    .hamburgerIcon {
        cursor: pointer;
    }

    .navWrapperLeft, .navwrapperRight {
        position:relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media only screen and (max-width: $b-medium ) {  
    .navgationBar {
        padding: 5px 15px;
    } 
}

@media only screen and (max-width: $b-large ) {  
     .hideOnMediumScreen{
        display: none;
    }
}