@import '../../../theme/theme.scss';

.movieTabItem {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px 4px 0 0 ;
  color: $primary-text;


  &:hover {
    background-color: rgba(165, 165, 165, 0.13);

  }
}

.listActive {
  background-color: rgba(165, 165, 165, 0.13);
  border-top: 1px #4a4a4a solid;
  border-left: 1px #4a4a4a solid;
  border-right: 1px #4a4a4a solid;
}


