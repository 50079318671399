@import '../../theme/theme.scss';

.sliderWrapper {
  display: flex;
  align-items: center;
  margin-top: 80px;


  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -4px !important;
  }
  .swiper-pagination-bullet-active {
    background: $secundary-color;
  }

  .swiper-button-prev, .swiper-button-next {
    color: $secundary-color!important;
  }

}
