@import '../../theme/theme.scss';

.notFoundWrapper {
  margin: 200px auto;
  text-align: center;

  p {
    cursor: pointer;
  }
}
