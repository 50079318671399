@import '../../../theme/theme.scss';

.triangleInfo {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  
  border-right:10px solid #6c6a6a;

  position: absolute;
  top: 50px;
  right: 50px;
  z-index: $tooltip-layer;
}

.MovieGridItemInfo {
  background-color: #6c6a6a;
  width: 300px;
  position: absolute;
  color: white;
  z-index: 1;
  height: auto;
  top: 30px;
  right: -250px;
  padding: 10px;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.42857143;
  text-align: left;
  cursor: default;


  p {
    margin: 0;
    line-height: 2;
  }

  .titleUppercase {
    text-transform: uppercase;
  }

}