.socialIconsWrapper {
  display: flex;
  img {
    width: 25px;
    margin: 0 5px 0 0;
  }
}

.alignSocialLeft {
  justify-content: left;
}

.alignSocialCenter {
  justify-content: center;
}