@import '../../theme/theme.scss';

.articleCardsWrapper {
  display: flex;
  align-items:  flex-start;
  justify-content: space-between;
  margin-top: 50px;
}

.noPaddingContainer {
  margin: 0 -15px;
}

.fixedBanner{
  margin-top: 2vw;
}

.fixedBanner img{
  width: 100%;
}
@media only screen and (max-width: $b-medium ) {
  .articleCardsWrapper {
    flex-direction: column;
  }
}