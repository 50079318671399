@import '../../theme/theme.scss';

.profileCard {
  color: $primary-text;
  width: 250px;
  text-align: center;

  .profilePhoto {
    border: 5px white solid;
    max-width: 220px;
  }

  .profileName {
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin: 1vh 0;
  }

  .profilePosition{
    font-weight: 200;
    font-size: 15px;
    margin: 1vh 0;
    text-transform: uppercase;
  }

  .profileEmail {
    font-weight: 200;
    font-size: 16px;
    margin: 1vh 0;
  }
}