@import '../../theme/theme.scss';

.article {
  .title {
    color: $primary-text;
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
    text-transform: uppercase;
  }
  
  .text {
    font-size: 22px;
    color: $primary-text;
    font-weight: 300;
  }
}

@media(max-width: $b-small) {
  .article {
    .title, .text {
      font-size: 18px;
    }
  }
}